/**
 * 封装（第二层封装,规定请求参数等）具体的user相关请求方法 （对应/src/store/modules/user.js，被其调用）
 * ++ 登录请求方法
 * ++ 获取用户信息请求方法
 * ++ 退出登录请求方法
 */
import request from "@/utils/request-handle"
import { wipeBase64Rrefix } from '../utils/index'
import qs from 'qs'

export const checkStudentStudyStatus = (id) => {
	return request({
		method: "get",
		url: "/student/checkStudentStudyStatus/" + id,
	});
}

export const getPrivacyPolicy = () => {
	return request({
		method: "get",
		url: "/api/article/APP01/1/1",
	});
}

export const idcardLogin = (data) => {
	return request({
		method: "post",
		url: "/api/login/byIdCard",
		data: qs.stringify(data),
	});
}

export const phoneLogin = (data) => {
	return request({
		method: "post",
		url: "/api/login/byMobile",
		data: qs.stringify(data),
	});
}

// 获取短信验证码
export const getPhoneCode = (data) => {
	return request({
		method: "post",
		url: "/api/login/sendSmsCode",
		data: qs.stringify(data),
	});
}

// 山东校验短信验证码
export const checkCode = (data) => {
	return request({
		method: "post",
		url: "/api/login/checkCode",
		data: qs.stringify(data),
	});
}

// 校验短信验证码
export const checkPhoneCode = (data) => {
	return request({
		method: "post",
		url: "/api/login/checkSmsCode",
		data: qs.stringify(data),
	});
}

// 系统消息的前端接口
export const sysMessage = (token, cityCode) => {
	return request({
		method: "get",
		url: "/sysMessage/list",
		params: {
			token,
			cityCode
		}
	});
}

// 获取支付状态
export function getPayStatus(data) {
	return request({
		method: "post",
		url: "/student/processPayStatus",
		data
	});
}

// 检测学员付费的支付状态
export function checkPayStatus(data) {
	return request({
		method: "post",
		url: "/student/checkOrder",
		data: qs.stringify(data)
	});
}

// 检测题库学员的支付状态
export function checkTkPayStatus(data) {
	return request({
		method: "post",
		url: "/student/checkTkOrder",
		data: qs.stringify(data)
	});
}

// 获取驾校合同html内容
export function getViewerContent(token, enterpriseId) {
	return request({
		method: "get",
		url: "/contract/viewer",
		params: {
			token,
			enterpriseId
		}
	});
}

// 微信授权
export function wxAuth(token, routeUrl) {
	return request({
		method: "get",
		url: "/wx/mp/buildWxMpConnectUrlLocal",
		params: {
			token,
			routeUrl
		}
	});
}

// 学员登录
export function login(data) {
	return request({
		method: "post",
		url: "/checkstudent",
		data,
	});
}

// 单点登录
export function singleLogin(data) {
	return request({
		method: "post",
		url: "/studentCheck",
		data
	});
}

//赛博学员信息同步
export function viewSbTotal(data) {
	return request({
		method: "post",
		url: "/saibo/viewSbTotal",
		data,
	});
}

//学员信息同步
export function infoSync(userId) {
	return request({
		method: "post",
		url: "/student/infoSync/" + userId,
	});
}

export function showLogInfo(data) {
	return request({
		method: "post",
		url: "/saibo/showLogInfo",
		data,
	});
}

// 学员报名
export function signup(data) {
	return request({
		method: "post",
		url: "/signup",
		data,
	});
}

// 上传图片
export function uploadImg(data, token) {
	return request({
		method: "post",
		url: "/oss/uploadFirstPhoto?token=" + token,
		data,
	});
}

// 更新图片
export function updateImg(data) {
	return request({
		method: "post",
		url: "/student/studentinfo/updatepic",
		data,
	});
}

// 获取学员信息
export function getInfo(token) {
	return request({
		method: "get",
		url: "/user/info",
		params: {
			token
		}
	});
}

export function getCitys() {
	return request({
		method: "get",
		url: "/base/citylistbyinitial",
	});
}

export function getCitysByName(name) {
	return request({
		method: "post",
		url: "/base/citysearch",
		data: {
			name,
		}
	});
}

export function getCitysByCode(code) {
	return request({
		method: "post",
		url: "/base/citysearch",
		data: {
			code,
		}
	});
}

export function logout() {
	return request({
		method: "post",
		url: "/user/loginOut"
	});
}

export function getUserBelongCompany() {
	return request({
		method: "get",
		url: "/company/list"
	});
}

// ====== 签到信息
export function getSignRecord(data) {
	return request({
		method: "post",
		url: "/student/studentsignrecord/list",
		data,
	});
}

// 获取学时
export function getPeriodInfo(id) {
	return request({
		method: "get",
		url: "/student/studentsignrecord/periodinfo/" + id,
	});
}

// 服务评价
export function doServiceComment(data) {
	return request({
		method: "post",
		url: "/student/commentservice/save",
		data,
	});
}

// 成绩列表
export function getScoreList(data) {
	return request({
		method: "post",
		url: "/student/studenttestrecord/getlist",
		data,
	});
}

// 重置学时
export function resetPeriodInfo(data) {
	return request({
		method: "post",
		url: "/student/resetperiod",
		data,
	});
}

// 微信环境授权
export const wxConfig = () => {
	const url = encodeURIComponent(window.location.protocol + '//' + window.location.hostname + '/index.html' + window.location.search)
	// const url = 'https://dev.h5.xunmeizaixian.com/index.html'
	return request({
		method: 'get',
		url: '/wx/mp/portal/getSignature?url=' + url,
		data: {}
	})
}

// 微信环境人脸对比
export const wxCompareFace = (data) => {
	data.signId = sessionStorage.getItem('signId') || null;
	return request({
		method: 'post',
		url: '/student/compareFaceWxMp',
		data: qs.stringify(data)
	})
}

// 人脸对比
export function compareFace(data) {
	data.photo = wipeBase64Rrefix(data.photo);
	data.signId = sessionStorage.getItem('signId') || null;
	return request({
		method: 'post',
		url: '/student/compareFaceBase64',
		data: qs.stringify(data)
	})
	// return request({
	// 	method: "post",
	// 	url: "/student/studentinfo/compareface",
	// 	data,
	// });
}

// 开始记录学时，20S提交一次
export const viewLogPush = (data) => {
	return request({
		method: 'post',
		url: '/viewLogPush',
		data
	})
}

// 签到
export function signIn(data) {
    data.signPic = wipeBase64Rrefix(data.signPic);
    return request({
        method: "post",
        url: "/student/studentsignrecord/save",
        data,
    });
}

// 签退
export function signOut(id, imgurl, mediaId) {
	return request({
		method: "post",
		url: "/student/studentsignrecord/signout/" + id,
		data: qs.stringify({ signPic: wipeBase64Rrefix(imgurl), mediaId, })
	});
}
// export function signOut(id, imgurl) {
// 	return request({
// 		method: "get",
// 		url: "/student/studentsignrecord/signout/" + id + '?signPic=' + imgurl,
// 	});
// }

// 视频列表
export function getVideoList(data) {
	if (JSON.stringify(data) === '{}') {
		return null
	}
	return request({
		method: "post",
		url: "/review/platform/select",
		data,
	});
}

// 观看视频
export function lastWatchLength(data) {
    return request({
        method: "post",
        url: "/student/getLastWatchLength",
        data,
    });
}

// 观看视频
export function watchVideo(data) {
	return request({
		method: "post",
		url: "/student/procWatchHistory",
		data,
	});
}

// 获取题目
export function getExamTitle(id) {
	return request({
		method: "get",
		url: "/chapter/gettestcourseinfo/" + id,
	});
}

// 获取最好成绩
export function getBest(data) {
	return request({
		method: "post",
		url: "/student/studenttestrecord/getbestscore",
		data
	});
}

// 获取试卷信息
export function getTestInfo(data) {
	return request({
		method: "post",
		url: "/chapter/getexamination",
		data
	});
}

// 交卷
export function handleSubmit(data) {
	return request({
		method: "post",
		url: "/student/studenttestrecord/save",
		data
	});
}

// 合格证明查询
export function findStudyCertByCertNumAndName(data) {
	return request({
		method: "post",
		url: "/XunMei/getInfoByCertNumAndName",
		data: qs.stringify(data)
	});
}

// 合格证明链接
export function getCertificate(studentId) {
	return request({
		method: "get",
		url: "/exp/certificate/" + studentId + "?json",
	});
}

// 合格证明列表
export function getStudyCertList(studentId) {
	return request({
		method: "get",
		url: "/certificate/getStudyCertList/" + studentId + "",
	});
}

// 获取合格证明下载链接地址
export function getCertDownloadUrl(planId, certificateId) {
	return request({
		method: "get",
		url: "/exp/download/" + planId + "/" + certificateId + "?format=img",
	});
}

// 重新生成合格证明下载链接地址
export function reloadCertDownloadUrl(planId, certificateId) {
	return request({
		method: "get",
		url: "/exp/download/" + planId + "/" + certificateId + "?format=img&reload=true",
	});
}

// 获取学习计划信息
export function getStudyPlanInfo(data) {
	return request({
		method: "post",
		url: "/studyplan/getPlanInfo",
		data
	});
}

// 获取学习计划信息
export function getStudyPlanInfoById(id) {
	return request({
		method: "post",
		url: "/studyplan/getPlanInfoById",
		data: {"id":id}
	});
}

// 重新生成合格证明下载链接地址
export function getVideoUrl(videoId, studentId) {
	return request({
		method: "get",
		url: "/review/platform/getVideoUrl/" + videoId + "/" + studentId,
	});
}

// 获取企业索引列表
export function getEnterpriseIndexList(data) {
	return request({
		method: "post",
		url: "/enterprise/search",
		data
	});
}

// 电子签名上传
export function uploadStudentSign(data) {
	data.studentSignPic = wipeBase64Rrefix(data.studentSignPic);
	return request({
		method: 'post',
		url: '/student/studentinfo/saveSignPic',
		data: qs.stringify(data)
	});
}

// 获取学习计划信息
export function getPlanInfo(id) {
	return request({
		method: "get",
		url: "/student/getPlanInfo/" + id,
	});
}

// 更换培训车型
export function changeTrainType(data) {
	return request({
		method: "post",
		url: "/student/changeTrainType",
		data
	});
}

// 答题成绩汇总
export function getstudyTotal(id) {
	return request({
		method: "get",
		url: "/XunMei/studyTotal/" + id,
	});
}

/* -------------------------------------- VIP题库 -------------------------------------- */

// 获取套餐信息
export function getProductPrice(categoryId,subject) {
	return request({
		method: "get",
		url: "/vip/vipFunction/getProductPrice",
		params: {
			categoryId,
			subject
		}
	});
}

// 获取商品名称
export function getProduct(categoryId,subject) {
	return request({
		method: "get",
		url: "/vip/vipFunction/getProduct",
		params: {
			categoryId,
			subject
		}
	});
}

// 获取密卷
export function getExamBefore(categoryId,subject) {
	return request({
		method: "get",
		url: "/vip/vipFunction/getExamBefore",
		params: {
			categoryId,
			subject
		}
	});
}

// 获取密卷id
export function getExamBeforeInfo(examinationId) {
	return request({
		method: "get",
		url: "/vip/vipFunction/getExamBeforeInfo",
		params: {
			examinationId
		}
	});
}

// 获取精简题
export function getConciseQuestion(categoryId,subject) {
	return request({
		method: "get",
		url: "/vip/vipFunction/getConciseQuestion",
		params: {
			categoryId,
			subject
		}
	});
}

// 获取试用精简题
export function getvipTrial(categoryId,subject) {
	return request({
		method: "get",
		url: "/vip/vipTrial/getConciseQuestion",
		params: {
			categoryId,
			subject
		}
	});
}

// 获取易考精选题视频
export function getVipVideo(categoryId,subject) {
	return request({
		method: "get",
		url: "/vip/vipFunction/getVipVideo",
		params: {
			categoryId,
			subject
		}
	});
}

// 获取课堂练习题编号
export function getExercisesId(data) {
	return request({
		method: "post",
		url: "/chapter/getExercisesId",
		data: qs.stringify(data)
	});
}

// 获取课堂练习题数据
export function getQuestion(data) {
	return request({
		method: "post",
		url: "/chapter/getQuestion",
		data: qs.stringify(data)
	});
}

// vip题库用身份证号码登录
export function checkCertNum(data) {
	return request({
		method: "post",
		url: "/vip/vipFunction/checkCertNum",
		data
	});
}

// vip题库登录验证
export function checkLogin(data) {
	return request({
		method: "post",
		url: "/vip/vipFunction/checkLogin",
		data
	});
}

// vip购买套餐
export function vipPay(data) {
	return request({
		method: "post",
		url: "/vip/vipFunction/vipPay",
		data
	});
}

// vip购买套餐
export function vipUserProduct(data) {
	return request({
		method: "post",
		url: "/vip/vipFunction/getUserProduct",
		data
	});
}

// vip激活学习卡
export function vipActivateCardPay(data) {
	return request({
		method: "post",
		url: "/vip/vipFunction/activateCardPay",
		data
	});
}

/* -------------------------------------- 辽宁继续教育 -------------------------------------- */

// 辽宁查询资格证信息
export function getSignupInfoLN(data) {
	return request({
		method: "post",
		url: "/liaoning/getSignupInfo",
		data
	});
}

// 辽宁提交报名信息
export function pushSignupInfoLN(data) {
	return request({
		method: "post",
		url: "/liaoning/pushSignUp",
		data
	});
}

// 辽宁是否学完当期课程
export function dongYuIsFinish(data) {
	return request({
		method: "post",
		url: "/liaoning/isFinish",
		data
	});
}

// 辽宁重新报名
export function dongYuReSignUp(data) {
	return request({
		method: "post",
		url: "/liaoning/reSignUp",
		data
	});
}

/* -------------------------------------- 山西双普 -------------------------------------- */

// 双普获取从业资格证信息
export function getStudentCerInfo(data) {
	return request({
		method: "post",
		url: "/sp/getStudentInfo",
		data
	});
}

// 双普提交报名信息
export function pushSignupInfo(data) {
	return request({
		method: "post",
		url: "/sp/pushSignUp",
		data
	});
}

// 双普查询资格证信息
export function getSignupInfo(data) {
	return request({
		method: "post",
		url: "/sp/getSignupInfo",
		data
	});
}

/* -------------------------------------- 乌海北方巨慧 -------------------------------------- */

// 乌海查询资格证信息
export function getSignupInfoWH(data) {
	return request({
		method: "post",
		url: "/JH/getSignupInfo",
		data
	});
}
// 慧视通身份校验
export function huiShiTongCheckStudent(data) {
	return request({
		method: "post",
		url: "/HST/checkStudent",
		data
	});
}
//学员是否学完
export function juHuiIsFinish(data) {
	return request({
		method: "post",
		url: "/JH/juHuiIsFinish",
		data
	});
}

//乌海资格证报名
export function whCertificateSignUp(data) {
	return request({
		method: "post",
		url: "/JH/whCertificateSignUp",
		data
	});
}

// 乌海提交报名信息
export function pushSignupInfoWH(data) {
	return request({
		method: "post",
		url: "/JH/studentSignUp",
		data
	});
}

// 乌海查询资格证信息
export function getStudentCerInfoWH(data) {
	return request({
		method: "post",
		url: "/JH/getStudentInfo",
		data
	});
}

export function uploadIdCardFrontAndBack(data) {
	data.idCardFront = wipeBase64Rrefix(data.idCardFront);
	data.idCardBack = wipeBase64Rrefix(data.idCardBack);
	return request({
		method: "post",
		url: "/oss/uploadIdCardFrontAndBack",
		data
	});
}

/* -------------------------------------- 山东好驾 -------------------------------------- */

// 山东好驾身份验证
export function checkStudent(data) {
	return request({
		method: "post",
		url: "/HaoJia/checkStudent",
		data
	});
}

// 山东好驾获取课程
// export function getStudentInfoHJ(data) {
// 	return request({
// 		method: "post",
// 		url: "/HaoJia/getStudentInfo",
// 		data
// 	});
// }

// 山东好驾上传课程
export function updateStudentHJ(data) {
	return request({
		method: "post",
		url: "/HaoJia/updateStudent",
		data
	});
}

// 山东好驾登录
export const shanDongByIdCard = (data) => {
	return request({
		method: "post",
		url: "/api/login/shanDongByIdCard",
		data: qs.stringify(data),
	});
}

// 山东好驾登录获取学员学习状态
export function getStudentStatus(data) {
	return request({
		method: "post",
		url: "/HaoJia/getStudentStatus",
		data
	});
}

// 绑定供应商
export function updateCertificateSd(data) {
	return request({
		method: "post",
		url: "/HaoJia/updateCertificateSd",
		data
	});
}
/* -------------------------------------- 山东全省继续教育 -------------------------------------- */
export function findStaffInfo(data) {
	return request({
		method: "post",
		url: "/SdProvinceCE/findStaffInfo",
		data
	});
}
export function findBLevel(data) {
	return request({
		method: "post",
		url: "/SdProvinceCE/findBLevel",
		data
	});
}
export function isFinish(data) {
	return request({
		method: "post",
		url: "/SdProvinceCE/isFinish",
		data
	});
}
export function reSignUp(data) {
	return request({
		method: "post",
		url: "/SdProvinceCE/reSignUp",
		data
	});
}

/* -------------------------------------- 常州教练员继续教育 -------------------------------------- */

export function getLastYear() {
	return request({
		method: "get",
		url: "/changzhou/getLastYear"
	});
}

export function getNewCertificate(token) {
	return request({
		method: "get",
		url: "/changzhou/getNewCertificate",
		params: {
			token
		}
	});
}

export function initCzStudent(data) {
	return request({
		method: "post",
		url: "/changzhou/initStudent",
		data: qs.stringify(data)
	});
}

export function getSignupInfoCZ(data) {
	return request({
		method: "post",
		url: "/changzhou/getSignupInfo",
		data: qs.stringify(data)
	});
}

export function signupCzStudent(data) {
	return request({
		method: "post",
		url: "/changzhou/signupStudent",
		data: qs.stringify(data)
	});
}

export function getMyStudyPlanRecord(data) {
	return request({
		method: "post",
		url: "/studentPlan/getCurStudentPlan",
		data
	});
}

export function changeMyStudyPlan(data) {
	return request({
		method: "post",
		url: "/studentPlan/changePlan",
		data
	});
}

// 检查学习记录是否已归档
export function recoveryCheckStatus(id) {
	return request({
		method: "get",
		url: "/recovery/checkStatus/" + id,
	});
}
// 执行学习记录还原过程
export function recoveryProc(id) {
	return request({
		method: "get",
		url: "/recovery/proc/" + id,
	});
}
/* -------------------------------------- 云南教练员继续教育 -------------------------------------- */
// 获取学员当前状态
export function checkCoachStatus(basePkId) {
	return request({
		method: "get",
		url: "/coach/checkCoachStatus",
		params: {
			basePkId
		}
	});
}
export function updateCoachInfo(id) {
	return request({
		method: "get",
		url: "/coach/updateCoachInfo",
		params: {
			id
		}
	});
}
/* -------------------------------------- 学员发票查询及提交申请 -------------------------------------- */
export function invoiceQuery(certNum) {
	return request({
		method: "get",
		url: "/invoice/getInvoiceOrder",
		params: {
			certNum
		}
	});
}
export function invoiceApply(data) {
	return request({
		method: "post",
		url: "/invoice/Invoicing",
		data
	});
}

/* -------------------------------------- 查询已上传学时 -------------------------------------- */
export function getUploadedPeriodInfo(subject) {
	return request({
		method: "get",
		url: "/period/getUploadedPeriodInfo/" + subject,
	});
}
export function uploadStagePeriod(subject) {
	return request({
		method: "get",
		url: "/period/uploadStagePeriod/" + subject,
	});
}
export function getUploadedPeriodInfoGD(subject) {
	return request({
		method: "get",
		url: "/period/getUploadedPeriodInfoGD/" + subject,
	});
}
/* -------------------------------------- 广州交信投 -------------------------------------- */
export function saveMiniProgramCallBack(data) {
	return request({
		method: "post",
		url: "/guangdong/stu/saveMiniProgramCallBack",
		data
	});
}
export function checkMiniProgramAuthData() {
	return request({
		method: "get",
		url: "/guangdong/stu/checkMiniProgramAuthData"
	});
}
